/* Layout */

// border-box with inheritance, just in case you need to override border-box somewhere

html {
  box-sizing: border-box;
  line-height: 1.3;
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1023px) {
  html {
    font-size: 0.8rem;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img {
  width: 100%;
  height: auto;
}

// div {
//   @include border-radius(.75em);
// }

table {
  width: 100%;
  border-top: none;

  // border-top: 1px solid $grey-mid;
  th {
    text-align: left;
    vertical-align: top;
    padding: 0.6em 0.35em 0.6em 0;

    .documents-list & {
      word-break: break-all;
    }
  }

  tr {
    border-bottom: 1px solid $grey-light;
  }

  td {
    word-break: normal;
    word-wrap: break-word;
    hyphens: auto;
    vertical-align: top;
    padding: 0.6em 0.35em 0.6em 0;
  }
}

.app-container {
  height: 100vh;
}

// Flexbox definitions

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  &>* {
    flex-grow: 1;
  }
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
}

// Layout elements

.logo {
  font-size: $std-font * 1.5;
  font-weight: normal;
  color: $blue;
  padding: 0 0.6em;
}

.head {
  width: 100%;
  position: fixed;
  height: 3em;
  z-index: 500;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  align-items: stretch;
  background-color: white;

  &>* {
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    display: flex;
    align-items: stretch;

    &>* {
      margin: auto;
    }
  }
}

.navbar--project,
.navbar--user {
  display: flex;
  align-items: stretch;

  &>ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  li {
    margin: 0;
    padding: 0;

    &:hover ul {
      display: flex;
    }
  }

  a {
    margin: 0;
    padding: 0.53rem;
    transition: all 0.5s ease-out;

    &:hover {
      background-color: $grey-light;
    }
  }

  ul ul {
    @include border-radius(0 0 4px 4px);
    display: none;
    height: auto;
    position: absolute;
    top: 3em;
    margin: 0;
    padding: 0;
    background-color: $white;
    flex-direction: column;
    align-items: stretch;
    list-style-type: none;
  }

  ul ul a {
    margin: 0;
    padding: 0.35em 0.75em;
    height: auto;
    width: 100%;
  }

  ul ul :last-child a {
    @include border-radius(0 0 4px 4px);
  }
}

.navbar--project {
  &>ul>li>a {
    font-size: $std-font * 1.5;
    font-weight: lighter;
  }
}

.navbar--user {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  &>ul>li>a {
    padding: 0.85em;
  }
}

.app-content {
  position: relative;
  z-index: 200;
  flex-grow: 1;
}

.room-nav,
.column-nav {
  color: white;
  overflow: visible;

  ul {
    margin: 0;
    padding: 0;
  }

  div,
  li {
    display: flex;
    align-content: stretch;
    align-items: stretch;
    list-style-type: none;
  }

  a {
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    display: block;
    margin: 0;
    color: white;
    transition: all 0.5s ease-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.room-nav {
  z-index: 2;
  height: 100vh;
  width: 100%;
  padding: 3em 0 0 0;
  position: fixed;
  pointer-events: none;

  & * {
    pointer-events: auto;
  }

  & a,
  & select {
    cursor: pointer;
  }

  &>div {
    width: 15em;
    background-color: $grey;
  }

  div,
  ul {
    display: flex;
    flex-direction: column;
  }

  .entry-list {
    position: relative;
    overflow: auto;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: flex-start;

    li {
      width: 15em;
      flex-grow: 1;
      flex-basis: 10%;
      max-height: 10%;
    }

    a {
      flex-grow: 1;
      padding-right: 10px;
    }
  }

  li {
    flex: auto;
    display: flex;
    flex-direction: column;
    height: 10%;
    flex-grow: 0;
  }

  a {
    flex-grow: 0;
    padding: 0.35em 10px 0.1em;
    line-height: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: all 0.5s ease-out;

    span {
      flex-basis: 2em;
    }

    span.light {
      flex-grow: 1;
    }

    &.active {
      padding-right: 0;
      border-right: $red 10px solid;
    }

    .room-id {
      font-weight: bold;
    }

    .light {
      font-weight: lighter;
      margin-left: 0.5em;
    }
  }
}

//noinspection CssInvalidPropertyValue
.room-nav {
  /* autoprefixer: off */
  height: -webkit-fill-available;
}

.room-nav .entry-list a.active:hover {
  border-right: $red 10px solid;
}

.column-nav {
  z-index: 1000;
  width: 100%;
  position: fixed;
  background-color: $grey;
  bottom: 0;
  left: 0;
  padding-left: 15em;
  height: 2.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  order: 2;

  li:not(.indicators--basis) {
    padding: 0.3em 0.5em;
  }

  a {
    padding: 0.5em 0.75em;
    text-align: center;
    border-bottom: transparent 5px solid;

    &.active {
      border-bottom: $red 5px solid;
    }
  }

  select {
    margin-bottom: 0;
  }
}

.navigate {
  a {
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
  }
}

.navigate,
.navigate a {
  height: 2.5em;
  flex-grow: 0;
}

.navigate-up a {
  background: transparent url(nav/arrows-up-white.svg) 50% 50% no-repeat;
  background-size: 24px;
  border-right: none;
}

.navigate-down a {
  background: transparent url(nav/arrows-down-white.svg) 50% 50% no-repeat;
  background-size: 24px;
  border-right: none;
}

.navigate-prev {
  width: 6em;

  a {
    background: transparent url(nav/arrow-left-white.svg) 2.4em 50% no-repeat;
    background-size: 24px;
  }
}

.navigate-next {
  width: 6em;

  a {
    background: transparent url(nav/arrow-right-white.svg) 2.4em 50% no-repeat;
    background-size: 24px;
  }
}

.outer-container {
  margin-left: 15em;
  padding-top: 3em;
  z-index: 1;
}

.room-data {
  margin: 0.5em 0 2em;

  //border-bottom: 1px solid $red; bei mozilla wird der untere tabellenrahmen beim bilderupload sichtbar
  th {
    width: 30%;

    .dropzone--files & {
      width: auto;
    }
  }
}

.dropzone--files {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  >div {
    max-width: 100%;
    padding-bottom: 2rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }
}

.dropzone--files .tertiary {
  margin-top: 0;
  margin-right: 0;
}

.room-title {
  padding: 1.2em;
  padding-bottom: 0;
  flex: 0 0 130px;
  z-index: 200;
}

.room-subtitle {
  padding: 1.2em;
  padding-bottom: 0;
  z-index: 200;
}

.room-content {
  z-index: 0;
  padding-bottom: 3em;
  position: relative;
}

.headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid $grey;

  .metaText {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-style: italic;
    color: $grey;
    & button {
      font-size: 1rem;
    }
  }
}

.departmententry-container {
  margin-top: 3rem;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;

  >textArea,
  >input,
  .department-entry-buttons-wrapper {
    width: 60%;
  }
}

.departmententry-content {
  display: flex;
  flex-direction: column;

  .left {
    padding-left: 0;
    padding-right: 2.4rem;
  }

  .middle {
    padding-left: 0;
    padding-right: 0;
    margin-top: -0.4rem;
    max-width: 65%;
  }

  img {
    min-height: 220px;
    object-fit: scale-down;
  }

  .departmententry-text-entry {
    display: flex;
    flex-direction: column;

    >a {
      display: block;
      width: fit-content;
    }

    .modal-edit-outer {
      margin-bottom: 0.5em;
    }
  }

  .department-entry-image-gallery {
    padding-bottom: 0;
  }

  >div {
    padding-top: 0.5rem;
  }
}

.content-box {
  padding: 1.2em;
  flex: 1 0 200px;
  max-width: 40%;

  &>div {
    padding-bottom: 3em;
  }

  &>div>div {
    margin-bottom: 1.5em;
    position: relative;
  }

  li {
    word-break: break-all;
  }
}

.shadow {
  @include shadow(0 4px 4px 0 rgba(34, 34, 34, 0.4));
}

.login-window {
  width: 200px;
  margin: 20vh auto;
}

.plain-page {
  padding: 1em 1em;
  font-size: 1.2em;
  width: 50%;
  min-width: 25em;
  margin: 0 auto;

  h3 {
    padding: 1em 0;
  }

  ul {
    margin: 0.3em 0;
    list-style-type: disc;
  }

  li {
    padding: 0.3em 0;
    font-size: inherit;
  }

  p {
    padding: 0.3em 0;
    font-size: inherit;
  }

  select,
  option {
    margin: 1em 0;
    font-size: 1.2em;
    font-family: 'ff-profile-web', sans-serif;
  }

  a {
    display: block;
    @include auto-rounded;
    margin: 0;
    padding: 0.6em 0.3em;
  }

  table {
    border-top: none;
  }

  td {
    border-bottom: 1px solid $grey-light;
  }

  &.projects-overview {
    padding: 4em 1em;
  }

  &.font-normal {
    font-size: inherit;
  }

  & .image-container {
    img {
      max-height: 50vh;
      width: auto;
    }
  }
}

.projects-overview {
  ul {
    list-style-type: none;
    margin: 1em 0;
    padding: 0;
    border-top: 1px solid $grey-light;
  }

  li {
    overflow: auto;
    width: auto;
    display: block;
    border-bottom: 1px solid $grey-light;
    padding: 0;
  }

  a {
    width: 100%;
    display: block;
    padding: 1em 0;
    transition: all 0.5s ease-out;
    background: url(nav/arrow-right.svg) 100% 50% no-repeat;
    background-size: 24px;

    &.disabled {
      cursor: default;
      color: #aaa;

      g {
        stroke: #aaa;
      }
    }

    g {
      stroke: #d41;
    }
  }

  a:hover,
  a:active {
    background-color: $grey-light;
  }
}

.empty {
  font-size: 1.2em;
  border-bottom: 1px $grey-light solid;
  height: 1.5em;
  display: block;
  background: $grey-light;
  padding: 0.6em;
  font-weight: lighter;
}

.catalog-entry-tooltip {
  display: flex;
  justify-content: space-between;

  >.delete-button {
    margin: 0;
  }

  .catalog-entry-tooltip-edit-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 16px;
    gap: 10px;
    width: 100%;
  }

  &.is-editing {
    .catalog-entry-tooltip-edit-buttons {
      >span {
        display: flex;
        align-items: flex-end;
        gap: 10px;

        >.delete-button {
          margin: 0;
        }
      }
    }
  }

  .tooltip-core {
    >button {
      float: left;
      color: $black;
      background-color: $grey-light;
      cursor: pointer;
    }

    .catalog-entry-tooltip-content {
      display: none;
    }

    &.visible {
      >button {
        color: $white;
        background-color: $grey;
      }

      .catalog-entry-tooltip-content {
        display: flex;
        position: absolute;
        justify-content: flex-end;
        border: 2px solid $grey-light;
        background: white;
        width: 100%;
        color: $black;
        padding: 15px;
        right: 101%;
        min-height: 300px;
        margin-top: -105px;

        >span {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          width: 100%;
        }
      }
    }
  }
}

.create-entity-select-catalog-button-container {
  padding-bottom: 8px;

  >.catalog-entry-tooltip {
    justify-content: flex-start;

    &.is-editing {
      >button {
        margin: 0.3em 0 0.3em 0.6em;
      }

      .tooltip-core {
        >button {
          margin: 0.3em 0 0.3em 0em;
        }
      }
    }
  }
}

.catalog-dialog-action-button {
  margin-top: 35px;
}

.catalog-edialog-display-content {
  overflow-y: auto;
  max-height: 70%;
}

.catalog-entry-list {
  .modal-edit-outer {
    display: inline;

    >span {
      display: inherit;
    }
  }

  tbody>tr>td>h4 {
    padding-top: 0;
    width: 100%;
  }

  td>span {
    padding: 0;
  }
}

.catalog-entries-list {
  h4 {
    width: 100%;
  }

  > :nth-child(2) {
    display: flex;
    gap: 5px;

    :first-child>input {
      flex: 0 1 30%;
    }
  }
}

.catalog-entry-details-table {
  table-layout: fixed;

  td {
    >a {
      max-width: 100%;
    }
  }

  &:not(.editing) {
    td {
      >span {
        padding-top: 0;
      }
    }
  }
}

.catalog-entries-header-row {
  > :first-child {
    padding-left: 10px;
  }
}

.catalog-entries-description-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 45px;
}

.catalog-dialog-action-container {
  &.mr-5 {
    margin-right: 5px;
  }

  &.left {
    float: left;
  }

  &.right {
    float: right;
  }
}

.catalog-details-content {
  margin: 0.5em 0 2em;

  > :first-child {
    max-width: 60%;

    .catalog-details-content-children {
      padding-top: 30px;

      >h4 {
        padding-top: 0;
        width: 100%;
      }
    }
  }
}

.catalog-entry-details {
  >h2 {
    display: flex;
    gap: 10px;
    width: 100%;

    >span {
      min-width: 1px;

      >.modal-edit-outer>span {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  >div {
    width: 70%;

    >div {
      padding-top: 3px;
      max-width: 70%;
    }
  }
}

.catalog-entry-list-table,
.catalog-entry-details-table {
  .modal-edit-outer {
    display: inline;

    >span {
      display: inherit;
    }
  }

  td>span {
    padding: 0;
  }
}

.catalog-entry-list-table {
  table-layout: fixed;
}

.catalog-list-row {
  > :first-child {
    padding-left: 10px;
  }

  &:not(.editing) {
    &:hover {
      cursor: pointer;
      background-color: $grey-light;
    }
  }

  .delete-button {
    float: right;
    margin: 0;
  }
}

.project-setting-catalogs-container {
  padding: 10px;
  border: 2px solid $grey-light;
}

// Positioning

.top-right {
  float: right;
  margin-bottom: 0.5em;
}

// Pulldowns etc.

.create-room {
  width: 15em;
  @include border-radius(0 0 4px 4px);
  display: block;
  position: absolute;
  margin-top: 2.5em;
  align-self: baseline;
  padding: 0 0.6em 0.6em;
  background-color: $white;
}

.center {
  display: flex;
  flex-grow: 1;
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;
}

.privacy-hint {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: $red;
  padding: 1em;
  color: $white;

  a {
    color: $white;
    text-decoration: underline;
  }
}

// dashboard

.dashboard {
  h4 {
    margin-bottom: 0;
  }

  img {
    width: 5.4em;
    float: left;
    height: auto;
    vertical-align: top;
    margin-right: 0.5em;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 0;
    border-bottom: 1px solid $grey-light;
  }

  .content-box a {
    margin: 0;
    padding: 0.5em 0.1em;
    width: 100%;
    border-radius: 0;
    background: none;
    display: flex;
    transition: all 0.5s ease-out;

    &:hover {
      background-color: $grey-light;
    }
  }
}

.dashboard--list-id {
  min-width: 3em;
  font-weight: bold;
  padding-right: 1em;
}

.dashboard--list-entry {
  flex-grow: 1;
  flex-basis: 6em;
}

.dashboard--list-meta {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 6em;
}

.dashboard--list-date {
  white-space: nowrap;
  text-align: right;
  padding-left: 1em;
}

.dashboard--list-time {
  font-weight: lighter;
}

.dashboard--editor {
  text-align: right;
  clear: both;
  color: $grey-mid;
  font-style: italic;
  padding-left: 1em;
}

.errorpage {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 1.5em;
    margin-bottom: 16px;
  }

  button {
    padding: 16px;
    font-size: 1.2em;
  }
}

#forgot-password-link {
  color: $blue;
  font-size: 14px;
  padding-top: 12px;
}

.ol-control {
  position: absolute;
  top: 20px;
  right: 20px;

  button {
    margin-bottom: 10px;
    background-color: $blue;
  }
}

.department-entry-data {
  margin: 0.5em 0 2em;

  th {
    width: 30%;

    .dropzone--files & {
      width: auto;
    }
  }

  .selection-data {
    padding: 0;

    >ul {
      list-style-type: none;
      padding-left: 0;

      >li {
        margin: 0.3em 0;
      }
    }
  }
}

.wall-data-right {
  display: flex;
  flex-direction: column;
}

.projectlist {
  opacity: 0;
  transition: all 200ms;

  &.animate {
    opacity: 1;
  }

  .list-item {
    opacity: 0;
    transform: translateX(-40px);
    transition: all 200ms;

    &.animate {
      opacity: 1;
      transform: translateX(0);
    }
  }
}