.notification-box {
  cursor: pointer;
  min-width: 300px;
  padding: 0 16px !important;
  &:hover {
    background-color: $grey-light;
  }
  & h4 {
    margin: 0;
    padding: 0.6em 0 0.3em;
    border-bottom: none;
  }
  &:first-child {
    &:hover {
      background-color: inherit;
    }
  }
}

.alert-box{
    background-color:#246;
    padding: 8px;
    color:#fff;
    border-radius: 5px;
    margin-left: 8px;
}

.override-styles {
    margin-bottom: 12px;
    padding: 16px 0 !important;

  & a{
        width: auto !important;
        padding: 0 !important;
        text-decoration: underline !important;
        color: #486A8C !important;
    }
}
.override-show-on-hover{
    display: flex !important;
    @include shadow(0 4px 4px 0 rgba(34, 34, 34, 0.4));
  > .notification-box.notification {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-top: 1px $grey-mid solid;
  }
}

.notice-read{
    font-weight: 100;
}

.notification-date {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 16px;
}

.notification-flex-container {
    display: flex;
    justify-content: space-between;
}

.notification-empty{
    padding: 12px 8px !important;
}

.notification-links {
  margin-top: 8px;
}

.notification-download {
  border-radius: 2px;
  font-weight: bold;
  color: $white !important;
  background-color: $grey;
  border: none;
  font-size: 0.8rem;
  padding: 0.3em 0.5em 0.25em;
  text-align: center;
}

.notification-download:hover {
  background-color: $grey !important;
}

.notification-opener{
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.85em;
}

.no-pad{
    padding: 0 !important;
}
