/* Overall definitions */

$black: #222;
$white: #fff;
$red: #d41;
$grey: #554;
$grey-mid: #887;
$grey-light: #eeeee2;
$blue: #246;

$std-font: 1rem;

@mixin shadow($shadow-xyboc) {
  box-shadow: $shadow-xyboc;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin replace {
  display: block;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin auto-rounded {
  float: left;
  width: auto;
  margin: .3em .6em;
  padding: .3em;
  @include border-radius(2px);
}

@mixin wide-rounded {
  width: 100%;
  margin: .3em 0;
  padding: .3em .6em;
  @include border-radius(2px);
}

@mixin border-bottom {
  border-bottom: 1px solid $grey-mid;
}
