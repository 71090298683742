#react-image-lightbox-portal {
  .outer {
    top: 44px !important;
  }
}

.react-spinner-container {
  margin: 0;
  padding: 0;
}

$rt-color-success: #246;
$rt-color-info: #3498db;
$rt-color-warning: #f1c40f;
$rt-color-error: #e74c3c;
$rt-font-family: 'ff-profile-web', sans-serif;
$rt-z-index: 9999;

.toolbox {
  position: absolute;
  align-items: flex-start !important;
  z-index: 1000;
  padding: 20px 0 0 20px;

  >button {
    width: fit-content;
    background-color: $rt-color-success;

    &.cancel {
      background-color: $rt-color-error;

      &:hover {
        background-color: lighten($rt-color-error, 10%);
      }
    }

    &:hover {
      background-color: lighten($rt-color-success, 10%);
    }
  }
}

.edit-object-btn {
  padding-left: 24px;
  background-size: 24px;

  .head & {
    background-size: 24px;
    font-weight: normal;
    margin: auto;
    height: 2.5em;
    color: $white;
  }

  &.delete {
    background: $rt-color-success url(nav/close-white.svg) 2px center no-repeat;
    background-size: 20px;

    .head & {
      padding-left: 2px;
      background: transparent url(nav/close-white.svg) 0 center no-repeat;
    }
  }

  &.add {
    margin-bottom: 5px;
    background: $rt-color-success url(nav/add-white.svg) 0 center no-repeat;

    .head & {
      background: transparent url(nav/add-white.svg) 0 center no-repeat;
    }
  }
}

.history-on-hover {
  padding: 10px 0;
}

.fa-history {
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
  cursor: pointer;
}

.fa-history.disabled {
  color: gray;
  cursor: default;
}

.history-on-hover:hover>.fa-history {
  opacity: 1;
}

.fa-history~span {
  opacity: 0;
  z-index: -10;
  transition: opacity ease-in-out 0.5s, z-index ease 1s;
  position: absolute;
  min-width: 120px;
  border: 1px solid #246;
  background: lightblue;
  padding: 8px 14px;
  margin-left: 20px;
  display: inline-block;
}

.fa-history:hover~span {
  opacity: 1;
  z-index: 100;
}

.text-on-hover~span {
  opacity: 0;
  z-index: -10;
  transition: opacity ease-in-out 0.5s, z-index ease 1s;
  position: absolute;
  min-width: 120px;
  border: 1px solid #246;
  background: lightblue;
  padding: 8px 14px;
  margin-left: 20px;
  display: inline-block;
  color: black;
}

.text-on-hover:hover~span {
  opacity: 1;
  z-index: 100;
}

.circle-tooltip {
  border: none;
  background: none;
  box-shadow: none;
  //color: white;
  color: black;
  font-weight: bold;
  cursor: pointer;

  &::before,
  &::after {
    border: none;
  }
}

.leaflet-control-zoom {
  & a {
    background-color: #246;
    font-size: 18px !important;
    padding: 0;

    &:hover {
      background-color: rgba(34, 68, 102, 0.6);
    }
  }
}