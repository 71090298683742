.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(85, 85, 68, 0.9);
  z-index: 10000;
}

.modal {
  position: absolute;
  top: 14%;
  left: 30%;
  right: 30%;
  bottom: 14%;
  padding: 8px 32px 32px 32px;
  background-color: white;
  font-family: 'ff-profile-web', sans-serif;
  overflow-y: auto;
  h2 {
    font-size: 2rem;
  }
  &.dragdrop {
    left: 0;
    right: 0;
    height: 500px;
  }

  &.inline-edit {
    top: 10%;
    left: 20%;
    right: 20%;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }

  .edit-mode-inline {
    flex-grow: 1;
  }

  .modal-edit-content,
  span {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
  }

  textarea {
    flex-grow: 1;
    font-size: 1.2em;
  }
}
