.search-found {
  font-weight: bold;
  color: $red;
}

.search-page {
  width: 100%;

  .flex-container {
    flex-grow: 1;
  }
}

#search-help {
  color: rgb(226, 151, 13);
  text-decoration: dashed underline;
  cursor: help;
}

#search-help-hover {
  opacity: 0;
  z-index: -10;
  transition: opacity ease-in-out 0.5s, z-index ease 1s;
  position: absolute;
  min-width: 120px;
  border: 1px solid #246;
  background: lightblue;
  padding: 8px 14px;
  margin-left: 20px;
  display: inline-block;
  color: black;
  & p {
    margin-bottom: 0.5em;
    &:last-child{
      margin:0;
    }
  }
}

#search-help:hover~#search-help-hover {
  opacity: 1;
  z-index: 100;
}