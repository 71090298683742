$base-duration: 250ms;
$spacer: 1rem;

.content-transition-enter {
  background-color: #554;
}

.content-transition-enter.content-transition-enter-active {
  background-color: white;
  transition: background-color $base-duration*2 ease-in;
}

.content-transition-leave {
  background-color: #554;
}

.content-transition-leave.content-transition-leave-active {
  background-color: white;
  transition: background-color $base-duration*2 ease-in;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity $base-duration ease-in;
}

.fade-exit,
.fade-exit-active,
.fade-exit-done {
  opacity: 1;
  background-color: red;
  font-size: 50px;
  z-index: 1;
}

.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

.modal-input {
	span, >div {
		padding: 8px;
	}
}

.accordion {
	&-item {
		border-top: 1px solid #ddd;
		&:last-child {
			border-bottom: 1px solid #ddd;
		}
	}
	&-trigger {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $spacer*0.5;
		cursor: pointer;
		h4 {
			margin: 0;
			color: #246;
		}
	}
	&-content {
		height: 0;
		padding-bottom: 0;
		padding-left: $spacer*1.5;
		padding-right: $spacer*0.5;
		overflow: hidden;
		transition: $base-duration ease;
		div {
			opacity: 0;
			transform: translateY(-$spacer*2);
			transition: $base-duration*1.5 ease;
		}
		&.is-active {
			height: 100%;
			padding-bottom: $spacer*0.5;
			div {
				opacity: 1;
				transform: translateY(0);
				transition: $base-duration*1.5 ease;
			}
		}
	}
	&-icon {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border: 0;
		background-color: transparent;
		&:before,
		&:after {
			position: absolute;
			content: '';
			width: 50%;
			height: 2px;
			background-color: #246;
			transition: $base-duration*2 ease;
		}
		&:before{}
		&:after {
			transform: rotate(90deg);
		}
		&.is-active {
			&:before {
				transform: rotate(360deg);
			}
			&:after {
				transform: rotate(360deg);
				transition-delay: 0.08s;
			}
		}
	}
}
