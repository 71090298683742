.droppable {
  display: flex;
  overflow-x: auto;

  &.dragging-over {
    background: $grey-light;
  }
  padding-bottom: 1em;
  margin-bottom: 0.8em;
}

.draggable {
  flex-direction: column;
  flex-wrap: nowrap;
  user-select: none;
  padding: 16px;
  padding-bottom: 0px;
  background: transparent;
  text-align: left;
  &.dragging {
    background: $grey-mid;
  }

  p {
    overflow-wrap: break-word;
    color: $grey;
    font-style: italic;
    padding-top: 5px;
  }
}
