/* Typography */

html {
  font-family: 'ff-profile-web', sans-serif;
  line-height: 1.2;
  font-size: $std-font;
}

h1 {
  font-size: $std-font;
  font-weight: normal;
  float: left;
  clear: both;
}

h2,
h2 input[type='text'] {
  font-size: $std-font * 2.5;
  font-weight: lighter;
  margin: 0.5em 0 0 0;
  clear: both;
  a,
  span:first-child {
    font-weight: bold;
  }
  .light {
    margin-left: 0.5em;
  }

  &.underline {
    border-bottom: 2px solid $grey;
  }
}

h2 input[type='text'] {
  margin-top: 0px;
  width: 4em;
}

h3 {
  margin: 0;
  padding: 0;
  clear: both;
}

h4 {
  font-size: $std-font * 1.2;
  line-height: 1;
  margin: 0 0 0.6em 0;
  padding: 1.2em 0 0.3em;
  border-bottom: 2px solid $grey;
  clear: both;
  span {
    font-weight: normal;
  }
}

h1,
h2,
h3,
h4 {
  &.info {
    color: $blue;
  }
  &.warning {
    color: $red;
  }
  &.error {
    color: $red;
  }
}

p {
  font-size: $std-font;
  margin: 0;
}

a {
  color: $red;
  text-decoration: none;
  display: inline-block;
  &.hint,
  &.hint:visited {
    color: $grey-mid !important;
    font-style: italic;
  }
}

.head a {
  color: $blue;
}
