/* Interaction */

:focus {
  //    outline: $grey-light solid 3px;
  outline: none;
  outline-offset: 0;
}

button,
.button {
  border-radius: 2px;
  width: auto;
  font-weight: bold;
  color: $white;
  background-color: $grey;
  border: none;
  float: right;
  font-size: 0.8rem;
  padding: 0.3em 0.5em 0.25em;
  white-space: nowrap;
  word-break: normal;
  hyphens: none;

  &.no-float {
    float: none;
    margin-bottom: 30px;
  }

  &.big {
    font-size: 0.9em;
  }
}

.button-home,
.plain-page .button-home {
  padding: 0.5em 0.5em 0.5em 24px;
  margin: 0;
  border-radius: 4px;
  background: $grey url(nav/arrow-left-white.svg) 0 center no-repeat;
  background-size: 24px;
}

.button-close {
  margin: 0;
  padding: 0 !important;
  display: block;
  overflow: hidden;
  text-indent: 200%;
  width: 24px;
  height: 24px;
  background: $grey url(nav/close-white.svg) center no-repeat;
  background-size: 24px;

  .room-details & {
    display: none;
  }
}

input,
textarea {
  @include wide-rounded;
  padding: 4px;
  border: 1px $grey solid;
  color: $black;
  width: 100%;

  &.edit_mode & {
    width: auto;
    float: left;
  }
}

/*
input[type="radio"],
input[type="checkbox"] {
	opacity: 0;
	width: 0;
  height: 0;
  margin: 0;
  display: none;
  & ~ label {
    padding-left: 28px;
    display: block;
    height: 24px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    caret-color: rgba(0,0,0,0);
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      background: transparent url(nav/checkbox.svg) 0 0;
      background-size: 24;
      transition: all .5s ease-out;
    }
  }
  &:checked ~ label {
    &:before {
      background: transparent url(nav/checkbox-selected.svg) 0 0;
    }
  }
}
input[type="radio"] {
  & ~ label:before {
    background: transparent url(nav/radio.svg) 0 0;
  }
  &:checked ~ label:before {
    background: transparent url(nav/radio-selected.svg) 0 0;
  }
}
*/

button:disabled {
  color: lighten($grey-mid, 50%);
  background-color: lighten($grey-mid, 40%);
}

.edit_mode,
.dropzone {
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.3em 0;
    padding: 0;
    list-style-type: none;
    position: relative;
    overflow: auto;
    clear: both;
  }

  .delete-button {
    float: left;
    clear: both;
  }
}

input[type='text'],
textarea {
  margin: 0 0 0.6em;
  color: $black;
}

.search-page {
  input[type='text'] {
    font-size: 24px;
    padding: 8px;
  }
}

input[type='number'] {
  margin: 0 0 0.6em;
  width: 4em;
  display: inline-block;
}

input[type='checkbox'],
input[type='radio'] {
  width: auto;
  margin: 0 4px 0 1px;
}

select {
  width: 100%;
  padding: 0.2em 24px 0.2em 0.5em;
  margin-bottom: 0.5em;
  @include border-radius(4px);
  appearance: none;
  font-family: inherit;
  color: $grey;
  border: 1px solid $grey;
  background: $white url(nav/arrows-choose.svg) 100% center no-repeat;

  .entry-filter-box &,
  .indicators & {
    color: $white;
    border: 1px solid $white;
    background: $grey url(nav/arrows-choose-white.svg) 100% center no-repeat;

    &:hover,
    &:active {
      background-color: $grey-mid;
    }
  }
}

.dropzone {
  border-width: 2px;
  border-style: dotted;
  border-radius: 5px;
  border-color: $grey;
  margin: 10px 0;
  padding: 0.6em;
  min-height: 54px;
  clear: both;
  overflow: visible;
  background: rgba($grey-light, 0.2);
}

.primary {
  float: right;
  margin: 0.3em 0 0.3em 0.6em;

  .small {
    padding: 0 !important;
  }
}

.secondary {
  background-color: $grey-mid;
  float: right;
  margin: 0.3em 0 0.3em 0.6em;
}

.tertiary {
  background-color: $grey-mid;
  margin: 0.3em 0.6em 0.3em 0;
  float: left;
}

button.delete-button {
  padding-left: 24px;
  background: $grey-mid url(nav/close-white.svg) 0 center no-repeat;
  background-size: 24px;
  font-weight: lighter;
}

button.add-button {
  padding-left: 24px;
  background: $grey url(nav/add-white.svg) 0 center no-repeat;
  background-size: 24px;

  .head & {
    background: transparent url(nav/add-white.svg) 0 center no-repeat;
    background-size: 24px;
    font-weight: normal;
    margin: auto;
    height: 2.5em;
    color: $white;
    transition: all 0.5s ease-out;

    &:hover {
      background-color: rgba($white, 0.15);
    }
  }
}

button.export-results-as-pdf {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.edit-button,
button.pdf-button {
  background-color: transparent;
  padding: 0.3em 0.6em;
  height: 2.5em;
  border-right: 1px solid rgba($white, 0.3);
  transition: all 0.5s ease-out;

  &:hover {
    background-color: rgba($white, 0.15);
  }

  .plain-page & {
    background-color: $grey;
    background-image: none;
    padding: 0.3em 0.6em;
    margin-bottom: 0.5em;
    color: $white;
  }
}

button.pdf-button-active {
  padding: 0.35em 0.75em;
}

.error {
  @include wide-rounded;
  font-size: 0.8em;
  padding-left: 32px;
  border: 1px $red solid;
  border-top-width: 5px;
  color: $red;
  background: lighten($red, 50%) url(nav/icon-warning-32.svg) no-repeat 8px 5px;
  background-size: 16px;
  word-break: normal;
}

.interstition {
  @include auto-rounded;
  position: relative;
  min-width: 10em;
  background: lighten($red, 50%);
  border: 1px $red solid;
  color: $red;
  border-top-width: 5px;
  padding: 0.6em 0.75em 0.5em 0.75em;
  z-index: 1000;
  word-break: normal;
  hyphens: none;
}

.roomplan-import-container {
  overflow: auto;
  padding-bottom: 40px;
}

.content-box,
.room-title,
.room-subtitle,
.project-settings {
  a {
    padding: 2px 14px 2px 4px;
    @include border-radius(2px);
    color: $black;
    background: $grey-light url(nav/edit-32.svg) no-repeat right 4px top 4px;
    background-size: 8px;
    transition: background-color 0.3s ease;

    &.plain {
      background: none;
      color: $red;
    }
  }

  .lightbox-trigger {
    background: none;
  }

  a:hover {
    background-color: rgba($grey-light, 0.5);
  }
}

div.map-navigation-container {
  padding-bottom: 0;

  .map-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    label {
      align-self: center;
    }
  }
}

.map-navigation-swicher {
  flex-grow: 0;
  flex-shrink: 0;
  @include border-bottom;
  margin: 0 0 0 0.6em;
  padding: 0;
  line-height: 1em;

  a {
    padding: 1em 0 1em 0;
    background: url(nav/arrow-right-white.svg) no-repeat center right;
    background-size: 24px;
  }

  a.active {
    padding-right: 10px;
    border-right: none;
    background: url(nav/close-white.svg) no-repeat center right;
  }
}

div.map-navigation-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  pointer-events: none;
  flex-direction: row;
  flex-wrap: nowrap;

  &>* {
    pointer-events: auto;
  }
}

.map-navigation-list {
  overflow-y: auto !important;
  -ms-overflow-style: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 15em;
  margin-left: 15em;
  padding-top: 3em;
  background-color: $grey;
  border-left: 1px solid $grey-mid;
  font-weight: bold;

  a {
    font-weight: 200;
    padding: 1em 20px 1em 0.6em;
    background: url(nav/arrow-right-white.svg) no-repeat center right;
    background-size: 24px;
  }

  a.active {
    padding-right: 10px;
    border-right: $red 10px solid;
    background-image: none;
  }

  ul {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: flex-start;

    li {
      height: auto;
    }
  }
}

span.map-navigation-list-building-name {
  display: block;
  margin: 0 0 0 0.6em;
  padding: 1em 0;
  line-height: 1em;
  font-weight: bold;
  @include border-bottom;
}

.map-navigation-layer .map-navigation-container {
  flex-grow: 1;
  background-color: $grey;
  padding: 4em 1% 2em 1.2em;
  border-left: 1px solid $grey-mid;

  h2 {
    margin: 0;
    padding: 0;

    &:after {
      content: ' \00A0';
    }
  }

  li {
    height: auto;
  }
}

.map-navigation-map {
  flex-grow: 1;

  .floorplan {
    max-width: 60%;
    max-height: 100%;

    text {
      display: none;
    }
  }

  .floorplan path,
  .floorplan line {
    fill: #fff;
    transition: fill 0.5s ease;
    stroke: #222;
    pointer-events: all;
    cursor: pointer;
  }

  .floorplan .active>path {
    fill: rgba($red, 0.5);
    stroke: $red;
  }

  .floorplan path:hover,
  .floorplan .active>path:hover {
    transition: fill 0.5s ease;
    fill: rgba($grey, 0.5);
    stroke: $grey;
  }
}

.map-navigation-layer {
  .map-navigation-map {
    flex-grow: 1;
    padding: 5%;

    .floorplan {
      max-width: 100%;
    }

    .floorplan path,
    .floorplan line {
      fill: $grey;
      stroke: #fff;
    }

    .floorplan path:hover,
    .floorplan .active>path:hover {
      fill: rgba(#fff, 0.2);
      stroke: #fff;
    }
  }
}

.entry-filter-box {
  flex-grow: 0;
  margin: 0.6em;
  @include border-bottom;
  padding-bottom: 0.6em;
  overflow: hidden !important;

  &>* {
    margin: 0.3em 0;
  }

  input[type='text'],
  textarea {
    color: $white;
    font-weight: normal;
    appearance: none;
    background-color: transparent;
    border: 1px solid $white;
    margin: 0.3em 0;
  }
}

a.reset-filter {
  text-align: center;
  width: auto;
  padding: 0.3em;
  font-weight: lighter;
  color: rgba($white, 0.5);
  text-decoration: underline;
}

.room-counter {
  flex-grow: 0;
  margin: 0.3em 0.6em;
  padding: 0;
}

.lightbox-trigger {
  cursor: pointer;
}

.image-navigation {
  padding: 0.75em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    float: left;
    margin: 0;
    width: 2rem;
    height: 2rem;
    text-indent: 200%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
  }

  .previous-image {
    border-radius: 4px 0 0 4px;
    background-image: url(nav/arrow-left-white.svg);
  }

  .next-image {
    border-radius: 0 4px 4px 0;
    background-image: url(nav/arrow-right-white.svg);
  }
}

.documents-list {
  ul {
    list-style: none;
    padding: 0;
  }

  a {
    padding: 2px 4px 2px 20px;
    background: transparent url(nav/icon-document-64.svg) no-repeat left 2px top 4px;
    background-size: 16px;
  }

  a:hover,
  a:active {
    background-color: $grey-light;
  }
}

.edit-tool {
  margin: 0 0 0 2em;
  background-color: $blue;
  @include border-radius(4px);
  height: 2em;
  align-self: center;
}

ul.dropdown {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;

  li {
    cursor: pointer;
    margin-left: 0;
    padding-left: 8px;
    list-style-type: none;
    background-color: $grey-light;

    &.highlighted {
      color: $red;
    }
  }
}

.add-member-container {
  background-color: $grey-light;
  margin-bottom: 8px;
  padding-right: 8px;
  padding-bottom: 40px;

  >div {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    input,
    select {
      font-size: 1.2em;
      padding: 4px;
      margin: 0;
    }
  }
}

#btn-confirm {
  background-color: $blue;
  margin-right: 12px;
}

#btn-deny {
  background-color: $red;
}

#confirm-save {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  height: 26px;

  p {
    padding: 3px 12px 0 0;
  }
}

.departmententry-content {
  a {
    padding: 2px 14px 2px 4px;
    @include border-radius(2px);
    color: $black;
    background: $grey-light url(nav/edit-32.svg) no-repeat right 4px top 4px;
    background-size: 8px;
    transition: background-color 0.3s ease;

    &.plain {
      background: none;
      color: $red;
    }
  }

  .lightbox-trigger {
    background: none;
  }

  a:hover {
    background-color: rgba($grey-light, 0.5);
  }
}